#section-call-to-action,
#section-recommendation,
nav,
aside,
.ajax,
footer#footer-site,
#header-benefits,
.no-print {
	display: none !important;
}

main {
	margin: 0 !important;
	padding: 0 !important;
	background: none !important;
}
